
.homeMaster {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  height:100vh;
  width: 100%;
}

.home-logo {
  //animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.home-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: white;
  justify-content: flex-start;
  align-items: center;
  padding-top: 150px;
  //background-color: yellow;
}

.home-title {
  font-size: 0.8em;
}

.home-button-row {
  padding-top: 20px;
  min-width: 250px;
  //background-color: aqua;
  justify-content: space-between;
}

.home-buttons {
  opacity: 0.3;
  margin: 5px;
  border-radius: 8px;
}



.links-row {
  padding-top: 50px;
  //background-color: aqua;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.links-text {
  font-size: 1.0em;
  color: #666666;
}

.links-text a{
  color: #000000;
  text-decoration: none;
}

.links-text:hover a {
  color: #1f8fe0;
}