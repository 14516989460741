.uhsllSpinner {
    background-color: #ffffff;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:80vh;
    width: 100%;
}

.masterUhsll {
    background-color: #ffffff;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height:80vh;
    width: 100%;
    padding: 10px;
}


.scoresWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.lineWrapper {
    //display: flex;
    //flex: 1;
    //flex-direction: row;
    //align-items: flex-start;
    //justify-content: flex-start;
}

.explanationWrapper {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 50px;
}
.col1 {
    width: 50px;
}

.colTeam {
    width: 150px;
}

.col2 {
    width: 200px;
}

.col3 {
    width: 100px;
}

.col4 {
    width: 100px;
}

.col5 {
    width: 100px;
}
.col6 {
    width: 100px;
}


.teamName {
    color: #000000;
    font-family: sans-serif;
    font-size: 12px;
    padding-top: 20px;
}

.teamNameClickable {
    color: #333333;
    font-family: sans-serif;
    font-size: 12px;
    padding-top: 20px;
}

.teamNameClickable:hover {
    color: #1f8fe0;
}

.firstRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.secondRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-left: 100px;
    padding-top: 5px;
}